<template>

    <div class="blog-area blog-detail">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="blog-item">
                        <div class="blog-content">
                            <ul class="blog-meta">
                                <li>
                                    <span class="author"><em>{{blog.by}}</em> {{blog.catname}}</span>
                                    <span class="common date">{{blog.date}}</span>
                                    <span class="common time">{{blog.time}}</span>
                                </li>
                            </ul>
                            <h2 class="blog-title">{{blog.title}}</h2>
                            <ul class="blog-tags">
                                <li>
                                    <a class="wordpress" href="#">WordPress</a>
                                </li>
                                <li>
                                    <a class="woocommerce" href="#">WooCommerce</a>
                                </li>
                                <li>
                                    <a class="magento" href="#">Magento</a>
                                </li>
                                <li>
                                    <a class="laravel" href="#">Laravel</a>
                                </li>
                            </ul>
                        </div>
                        <div class="blog-img">
                            <img src="/images/blog/large-size/1-1-1170x550.webp" alt="Blog Image">
                        </div>
                    </div>
                    <div class="blog-detail-info">
                        <h2 class="title">Make your store stand out from the others by converting more shoppers
                            into buyers!
                        </h2>
                        <p class="desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                            Ipsum has been the indu stry's standard dummy text ever since the 1500s when an unknown
                            prnter
                            took a galley of type and scramb has been text ever since them 1500s unknown type and scramb
                            led
                            it to make a type specimen book.
                        </p>
                        <p class="desc">
                            There are many variations of passages of Lorem Ipsum available, but the majority have
                            suffered
                            alteration in some form,
                            by injected humour, or randomised words which don't look even slightly believable. If you
                            are
                            going to use a passage of orem Ipsum, you need to be sure there isn't anything embarrassing
                            hidden in the middle of text. All the Lorem psum generators on the Internet tend predefined
                            chunks as necessary, making this the first true generator on the Internet. It uses
                            dictionary of
                            over Latin words combnes with a handful of model sentence structures.
                        </p>
                        <div class="list-area">
                            <h2 class="title">Table of Content:</h2>
                            <ul class="list-item">
                                <li>
                                    <a href="#">
                                        <i class="icofont-double-right"></i>
                                        It was popularised in the 1960s with the release of Letraset sheets containing
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="icofont-double-right"></i>
                                        Many desktop publishing packages and web page editors now use
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="icofont-double-right"></i>
                                        It was popularised in the 1960s with the release of Letraset sheets containing
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="icofont-double-right"></i>
                                        Many desktop publishing packages and web page editors now use
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="icofont-double-right"></i>
                                        It was popularised in the 1960s with the release of Letraset sheets containing
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <p class="desc">
                            There are many variations of passages of Lorem Ipsum available, but the majority have
                            suffered alteration in some form, by injected humour, or randomised words which don't look even
                            slightly believable. If you are going to use a passage of orem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem psum generators on the Internet tend predefined chunks as necessary, making this the first true generator on the Internet. It uses dictionary of over Latin words combnes with a handful of model sentence structures.
                        </p>
                        <h2 class="title">Our company fails the real world test in all kinds of ways.</h2>
                        <p class="desc">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the indu stry's standard dummy text ever since the 1500s when an unknown prnter took a
                            galley of type and scramb has been text ever since them 1500s unknown type and scramb led it to make a type specimen book.
                        </p>
                        <p class="desc mb-0">
                            There are many variations of passages of Lorem Ipsum available, but the majority have
                            suffered alteration in some form, by injected humour, or randomised words which don't look even
                            slightly believable. If you aregoing to use a passage of orem Ipsum, you need to be sure there isn't
                            anything embarrassing hidden in the middle of text. All the Lorem psum generators on the Internet tend predefined chunks as necessary, making this the first true generator on the Internet. It uses dictionary of over Latin words combnes with a handful of model sentence structures.
                        </p>
                        <div class="single-img">
                            <img src="/images/blog/large-size/1-2-973x453.webp" alt="Blog Image">
                        </div>
                        <h2 class="title style-2">
                            Well, that wasn’t the only unconventional thing 37 Signals did on their way up.
                        </h2>
                        <p class="desc">
                            There are many variations of passages of Lorem Ipsum available, but the majority have
                            suffered alteration in some form, by injected humour, or randomised words which don't look even
                            slightly believable. If you are going to use a passage of orem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem psum generators on the Internet tend predefined chunks as necessary, making this the first true generator on the Internet. It uses dictionary of over Latin words combnes with a handful of model sentence structures.
                        </p>
                        <p class="desc mb-0">
                            There are many variations of passages of Lorem Ipsum available, but the majority have
                            suffered alteration in some form, by injected humour, or randomised words which don't look even
                            slightly believable. If you are going to use a passage of orem Ipsum, you need
                            to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem
                            psum generators on the Internet tend predefined chunks as necessary, making this the first true generator on the Internet. It uses dictionary of over Latin words combnes with a handful of model sentence structures.
                        </p>
                        <div class="blog-quote">
                            <h2 class="title">2,83k People Receive Our Weekly WordPress Related Newsletter.
                            </h2>
                        </div>
                        <p class="desc">
                            There are many variations of passages of Lorem Ipsum available, but the majority have
                            suffered alteration in some form, by injected humour, or randomised words which don't look even
                            slightly believable. If you are going to use a passage of orem Ipsum, you need
                            to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem
                            psum generators on the Internet tend predefined chunks as necessary, making this the first true
                            generator on the Internet. It uses dictionary of over Latin
                            words combnes with a handful of model sentence structures.
                        </p>
                        <p class="desc mb-0">
                            There are many variations of passages of Lorem Ipsum available, but the majority have
                            suffered
                            alteration in some form, by injected humour, or randomised words which don't look even
                            slightly
                            believable. If you are going to use a passage of orem Ipsum, you need to be sure there isn't
                            anything embarrassing hidden in the middle of text. All the Lorem psum
                            generators on the Internet tend predefined chunks as necessary, making this the first true
                            generator on the Internet. It uses dictionary of over Latin
                            words combnes with a handful of model sentence structures.
                        </p>
                        <div class="blog-social-link">
                            <h2 class="title">Share this article:</h2>
                            <ul class="social-list">
                                <li v-for="(social, index) in socials" :key="index">
                                    <a :href="social.link"><i v-bind:class="social.iconName"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

 <script>
import blogs from '../data/blog.json'

export default {
  data () {
    return {
      blogs,
      id: this.$route.params.id,
      socials: [
        {
          link: 'https://www.facebook.com',
          iconName: 'icofont-facebook'
        },
        {
          link: 'https://www.skype.com',
          iconName: 'icofont-skype'
        },
        {
          link: 'https://www.twitter.com',
          iconName: 'icofont-twitter'
        }
      ]
    }
  },
  computed: {
    blog () {
      return this.blogs.blogs.find(blog => blog.id === Number(this.id))
    }
  }
}
 </script> 
